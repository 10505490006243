#root .ui.page.modals.transition.visible {
  display: flex !important;
}


#root .ui.scrolling.modal {
  max-height: 95dvh;
  overflow-y: auto;

}

#root .ui.modal .content {
  overflow-y: auto;
  @apply rounded-xl;
}

.ui.fullscreen.modal>.close.inverted {
  color: white;
  color: #7f15ff;
}

.pointer-events-none {
  pointer-events: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.flex-1 {
  flex: 1;
}

#root .drop-shadow {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.3));
}

#root .App.no-animations .animated {
  animation-duration: 0ms !important;
}

#root .App.go-animations .animated {}

a.ui {
  color: #001b44;
}

a.ui:hover {
  color: #001b44;
}

#root .ui.button,
#root .ui.dropdown,
.ui.input>input {
  border-radius: 4px !important;
}

#root a.ui.button {
  display: flex;
  display: block;
  align-items: center;
}


#root .ui.fluid.right.labeled input {
  border-radius: 4px 0 0 4px !important;
}

#root .ui.fluid.right.labeled .label {
  border-radius: 0 4px 4px 0 !important;
}

button,
input {
  border-radius: 4px;
}

#root .ui.button.basic.primary {
  background-color: rgba(13, 113, 187, 0.08) !important;
}

#root .ui.button.basic.primary:hover {
  background-color: rgba(13, 113, 187, 0.15) !important;
}

#root .ui.button.basic.primary:focus-visible {
  box-shadow: inset 0 0 0 2px #357aba !important;
}

#root .ui.button.basic:focus-visible {
  box-shadow: inset 0 0 0 1px #9a9a9a !important;
}

.ui.button.tiny {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
}

.ui.right.corner.label i.icon {
  margin-top: -3px;
}

.ui.cards a.card,
.ui.link.card,
.ui.link.cards .card,
a.ui.card {
  transform: translateY(0) !important;
}

.OperativeDropdown>summary {
  list-style-type: none;
}

.OperativeDropdown>summary::-webkit-details-marker {
  display: none;
}

.OperativeDropdown>summary::after {
  padding-left: 0.5rem;
  content: "▾️";
}

.OperativeDropdown[open]>summary::after {
  content: "▴";
}

@media print {
  .no-print {
    display: none;
  }

  #klaro {
    display: none;
  }
}

@media (max-width: 768px) {

  #root .no-margin-mobile {
    margin: 0 !important;
    width: 100% !important;
  }

  .tire-group {
    margin: 0 !important;
  }
}

.BarIcon svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  cursor: pointer;
}

.BarIcon svg path {
  fill: none;
  stroke: white;
  stroke-width: 4px;
}


#top,
#bottom {
  stroke-dasharray: 30, 75.39;
  transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
}

.BarIcon svg.active #top,
.BarIcon svg.active #bottom {
  stroke-dasharray: 75.39;
  stroke-dashoffset: -60;
}

.BarIcon svg:nth-child(2) {
  transform: rotate(0deg);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.BarIcon svg:nth-child(2) path {
  transition: all 0.4s ease-in-out 0.6s;
}

.BarIcon svg:nth-child(2).active {
  transform: rotate(180deg);
}

.moreVehiclesList summary::after {
  content: "anzeigen";
}

.moreVehiclesList[open] summary::after {
  content: "verbergen";
}

.order-overview__details td {
  border-top: 0 !important;
}


#root .ui.fluid.dropdown .ui.label {
  margin-top: -6px !important;
}